import React, { Component } from 'react';
import { graphql } from 'gatsby';
import { Layout, SliceZone } from '../components';

class FundraisingPage extends Component {
  render() {
    const {
      data: {
        site: {
          siteMetadata,
        },
        page,
      },
      location,
    } = this.props;
    const {
      uid: pageSlug,
      data: pageData,
    } = page;
    const {
      pageTitle,
      metaTitle,
      metaDescription,
      openGraphImage,
      body,
    } = pageData;
    const seoData = {
      metaTitle,
      metaDescription,
      openGraphImage,
    };
    return (
      <Layout location={location} seoData={seoData}>
        <SliceZone
          pageSlug={pageSlug}
          pageTitle={pageTitle}
          siteMetadata={siteMetadata}
          allSlices={body}
          location={location}
        />
      </Layout>
    );
  }
}

export default FundraisingPage;

export const pageQuery = graphql`
  query FundraisingPageQuery($uid: String!) {
    site {
      siteMetadata {
        blogSlug,
      }
    },
    page: prismicFundraisingPage(uid: { eq: $uid }) {
      uid
      data {
        pageTitle: page_name {
          text
        }
        metaTitle: meta_title {
          html
          text
        },
        metaDescription: meta_description {
          html
          text
        },
        openGraphImage: open_graph_image {
          alt
          copyright
          url
        }
        body {
          ... on PrismicFundraisingPageBodyPageHero {
            id
            sliceType: slice_type
            primary {
              headline {
                text
              }
              subtitle {
                text
              }
              illustrationStyle: illustration_style
              illustrator {
                document {
                  ... on PrismicArtist {
                    data {
                      name {
                        text
                      }
                    }
                  }
                }
              }
              description {
                text
              }
              illustration {
                fluid(maxWidth: 1200) {
                  ...GatsbyPrismicImageFluid_withWebp
                }
                alt
              }
              theme
            }
          }
          ... on PrismicFundraisingPageBodyBreadcrumbs {
            id
            sliceType: slice_type
            primary {
              theme
            }
            items {
              pageLabel: page_label {
                text
              }
              pageLink: page_link {
                target
                slug
                linkType: link_type
                url
              }
            }
          }
          ... on PrismicFundraisingPageBodyGridBlock {
            id
            sliceType: slice_type
            primary {
              theme
              photoAlignment: photo_alignment
              grayscale
              headline {
                text
              }
              photo {
                fluid(maxWidth: 1200) {
                  ...GatsbyPrismicImageFluid_withWebp
                }
                alt
              }
              description {
                html
              },
            }
            items {
              buttonLabel: button_label {
                text
              }
              buttonLink: button_link {
                slug
                url
                type
                linkType: link_type
              }
            }
          }
          ... on PrismicFundraisingPageBodyContactFormBlock {
            id
            sliceType: slice_type
            primary {
              title {
                text
              }
            }
          }

          ... on PrismicFundraisingPageBodyTextHero {
            id
            sliceType: slice_type
            primary {
              theme
              headline {
                text
                html
              }
              description {
                text
                html
              }
            }
            items {
              buttonLabel: button_label {
                text
              }
              buttonLink: button_link {
                url
              }
            }
          }
          ... on PrismicFundraisingPageBodyCallToActionTiles {
            id
            sliceType: slice_type
            primary {
              title {
                text
              }
              itemsPerRow: items_per_row
            }
            items {
              image {
                fluid(maxWidth: 1200) {
                  ...GatsbyPrismicImageFluid_withWebp
                }
                alt
              }
              title {
                text
                html
              }
              description {
                text
                html
              }
              buttonLabel: button_label {
                text
              }
              buttonLink: button_link {
                url
              }
            }
          }
          ... on PrismicFundraisingPageBodyResourceTiles {
            id
            sliceType: slice_type
            primary {
              theme
            }
            items {
              image {
                fluid(maxWidth: 1200) {
                  ...GatsbyPrismicImageFluid_withWebp
                }
                alt
              }
              title {
                text
                html
              }
              description {
                text
                html
              }
              buttonLabel: button_label {
                text
              }
              buttonLink: button_link {
                url
              }
            }
          }
          ... on PrismicFundraisingPageBodyImageBlock {
            id
            sliceType: slice_type
            primary {
              theme
              image {
                fluid(maxWidth: 1200) {
                  ...GatsbyPrismicImageFluid_withWebp
                }
                alt
              }
            }
          }
          ... on PrismicFundraisingPageBodyVideoBlock {
            id
            sliceType: slice_type
            primary {
              theme
              videoProvider: video_provider
              videoUrl: video_url {
                url
              }
            }
            items {
              buttonLabel: button_label {
                text
              }
              buttonLink: button_link {
                url
              }
            }
          }
        }
      }
    }
  }
`;
